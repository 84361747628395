.small {
  --photo-size: 0.9rem;
  --icon-size: 0.5rem;
  --font-size: 0.85rem;
}

.medium {
  --photo-size: 1rem;
  --icon-size: 0.65rem;
  --font-size: 1rem;
}

.large {
  --photo-size: 1.5rem;
  --icon-size: 0.9rem;
  --font-size: 1.25rem;
}

.container {
  align-items: center;
  display: inline-flex;
  margin-right: 1rem;
}

.container:last-child {
  margin-right: 0;
}

.photo {
  align-items: center;
  background-color: var(--color-primary-accent);
  background-size: cover;
  border-radius: 50%;
  color: var(--color-primary-accent-p75);
  display: flex;
  font-size: var(--icon-size);
  font-weight: bold;
  justify-content: center;
  height: var(--photo-size);
  margin-right: 0.25rem;
  width: var(--photo-size);
}

.display_name {
  font-size: var(--font-size);
  font-weight: 600;
}
