.login_container {
  margin: 2rem auto;
  width: 50rem;
}

.logo {
  display: flex;
  margin-bottom: 3rem;
}

.logo img {
  display: flex;
  margin: auto;
  max-height: 5rem;
}
