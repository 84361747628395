.container {
  flex: 1;
  position: relative;
}

.selector {
  background: var(--color-gray-light-p12);
  border: 1px solid var(--color-gray-light-n25);
  border-radius: 4px;
  color: var(--color-gray-dark-n75);
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.25s;
}

.selector:hover {
  background: var(--color-gray-light);
  border-color: var(--color-gray-light-n37);
}

.selector.is_expanded {
  border-radius: 4px 4px 0 0;
}

.selector_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  margin-right: 0.5rem;
  transition: 0.25s transform;
}

.arrow.is_expanded {
  transform: rotate(180deg);
}

.options {
  background-color: var(--color-gray-light-p87);
  border: 1px solid var(--color-gray-light-n25);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 13px 15px 1px var(--color-gray-light-n50);
  list-style-type: none;
  position: absolute;
  width: 100%;
  width: -webkit-fill-available;
  z-index: 999;
}

.options li {
  cursor: pointer;
  padding: 0.25rem;
}

.options li:hover {
  background: var(--color-gray-light-n12);
}

.section_header {
  color: var(--color-gray-light-n50);
  font-size: 0.85rem;
  font-weight: 600;
}

.multi_select_options {
  padding: 0.5rem;
}
