h1.heading {
  color: var(--color-gray-dark-p12);
  font-size: 1.45rem;
  font-weight: 800;
}

h2.heading {
  color: var(--color-gray-dark-p12);
  font-size: 1.15rem;
  font-weight: 800;
}

h3.heading {
  color: var(--color-gray-dark-p25);
  font-size: 1.05rem;
  font-weight: 800;
}

.subtitle {
  color: var(--color-gray-dark-p50);
  font-size: 75%;
  font-weight: 600;
}

.page_title {
  margin-bottom: 2rem;
}
