.container {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
}
