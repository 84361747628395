.container {
  align-items: center;
  background: var(--color-gray-light-p12);
  border: 1px dashed var(--color-gray-light-n25);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: 1rem;
  transition: all 0.25s;
}

.container:hover {
  background: var(--color-gray-light);
  border-color: var(--color-gray-light-n37);
}

.file_input {
  display: none;
}
