.container {
  position: relative;
}

.progress {
  position: absolute;
  left: -1.35rem;
  right: -1.35rem;
  top: 0.5rem;
}

.progress progress {
  height: 6px;
  width: 100%;
}
