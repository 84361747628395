.container {
  background-color: var(--color-gray-light-p87);
  border: 1px solid var(--color-gray-light-n25);
  border-radius: 4px;
  display: flex;
  flex: 1;
}

.text_input {
  background: transparent;
  border: 0;
  color: var(--color-gray-dark-n75);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  outline: 0;
  padding: 0.5rem;
  width: 100%;
}

.disabled {
  background-color: var(--color-gray-light-n12);
  cursor: not-allowed;
}

.prefix {
  background: var(--color-gray-light-p12);
  border-radius: 4px 0 0 4px;
  border-right: 1px solid var(--color-gray-light-n25);
  color: var(--color-gray-light-n37);
  padding: 0.5rem;
}
