.link {
  color: var(--color-gray-light-n62);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  flex-direction: row;
  margin: -0.5rem;
  padding: 1rem 0.75rem;
  text-decoration: none;
  transition: all 0.25s;
}

.link:hover {
  color: var(--color-gray-light-n75);
}

.highlight_right {
  border-right: 4px solid transparent;
}

.highlight_bottom {
  border-bottom: 4px solid transparent;
  padding-bottom: 0.75rem;
}

.active {
  border-color: var(--color-primary-accent-n75);
  color: var(--color-primary-accent-n75);
}

.icon {
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
}

.link_section {
  border-bottom: 1px solid var(--color-gray-light-n12);
  border-top: 1px solid var(--color-gray-light-n12);
  margin: 0.5rem -0.5rem 0 -0.5rem;
  transition: all 0.25s;
}

.link_section .title_container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.title_content {
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
}

.link_section .title_content {
  padding: 1rem 0.75rem;
}

.nav_header {
  margin-top: 0.75rem;
  padding: 0.75rem 0.25rem;
}

.chevron {
  margin-left: auto;
  margin-right: 1rem;
  transition: all 0.25s;
}

.link_section.expanded .chevron {
  transform: rotate(90deg);
}

.link_section:hover,
.link_section.expanded {
  background-color: var(--color-gray-light-n25);
  border-bottom: 1px solid var(--color-gray-light-n37);
  border-top: 1px solid var(--color-gray-light-n37);
}

.link_section.expanded {
  padding-bottom: 0.5rem;
}

.link_section .link {
  display: flex;
  margin-right: 0;
  padding: 1rem 0.75rem;
}

.link_section .content {
  padding-left: 0.5rem;
}

@media (max-width: 1000px) {
  .highlight_bottom.active {
    border-bottom: 0;
    border-left: 4px solid var(--color-primary-accent-n75);
  }
}
