* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

:root {
  /* Prima colour scheme */
  --color-primary-accent: #256eff;
  --color-primary-accent-p12: #3f7fff;
  --color-primary-accent-p25: #5b91ff;
  --color-primary-accent-p37: #76a4ff;
  --color-primary-accent-p50: #91b6ff;
  --color-primary-accent-p75: #c8daff;
  --color-primary-accent-n12: #0055fe;
  --color-primary-accent-n37: #003db6;
  --color-primary-accent-n75: #001849;

  --color-gray-light: #eef0f2;
  --color-gray-light-p12: #f0f2f3;
  --color-gray-light-p25: #f2f4f5;
  --color-gray-light-p50: #f6f7f8;
  --color-gray-light-p87: #fdfdfd;
  --color-gray-light-n12: #ccd2d8;
  --color-gray-light-n25: #aab4be;
  --color-gray-light-n37: #8896a3;
  --color-gray-light-n50: #687887;
  --color-gray-light-n62: #4e5a66;
  --color-gray-light-n75: #343c44;
  --color-gray-light-n87: #1a1e22;

  --color-gray-dark: #202c39;
  --color-gray-dark-p12: #324559;
  --color-gray-dark-p25: #455f7b;
  --color-gray-dark-p50: #7894b3;
  --color-gray-dark-p75: #bbcad9;
  --color-gray-dark-p87: #dde4ec;
  --color-gray-dark-n12: #1b2631;
  --color-gray-dark-n25: #17202a;
  --color-gray-dark-n50: #10151c;
  --color-gray-dark-n75: #080b0e;

  --color-highlight: #ffb140;
  --color-highlight-p25: #ffc471;
  --color-highlight-p50: #ffd8a1;
  --color-highlight-p62: #ffe2b8;
  --color-highlight-p75: #ffebd0;
  --color-highlight-p87: #fff5e7;
  --color-highlight-p12: #ffa01a;
  --color-highlight-n25: #f18d00;

  --color-highlight-red: #f82731;
  --color-highlight-red-p25: #fa5c64;
  --color-highlight-red-p50: #fc9297;
  --color-highlight-red-p75: #fdc9cb;
  --color-highlight-red-p87: #fee4e5;
  --color-highlight-red-n12: #f20713;
  --color-highlight-red-n25: #d00610;
  --color-highlight-red-n37: #ad050e;
  --color-highlight-red-n62: #680308;

  --color-highlight-green: #109648;
  --color-highlight-green-p37: #3eeb86;
  --color-highlight-green-p75: #b2f7cf;
  --color-highlight-green-n25: #0c7237;

  --color-highlight-blue: #0055fe;

  --color-highlight-orange: #f18d00;

  /* End of Prima colour scheme */
}

html,
body {
  height: 100%;
}

body,
#root {
  background: var(--color-gray-light);
  color: var(--color-gray-dark-n75);
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}
