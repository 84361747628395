.container {
  background-color: var(--color-gray-light-p87);
  border: 1px solid var(--color-gray-light-n25);
  border-radius: 4px;
  display: flex;
  flex: 1;
}

.textarea {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  font-family: Consolas, Monaco, Courier, monospace;
  font-size: 1rem;
  flex: 1;
  line-height: 1.25rem;
  min-height: 2rem;
  outline: 0;
  padding: 0.5rem;
  resize: vertical;
}

.no_resize {
  resize: none;
}

.line_numbers {
  background: var(--color-gray-light-p12);
  border-radius: 4px 0 0 4px;
  border-right: 1px solid var(--color-gray-light-n25);
  color: var(--color-gray-light-n25);
  font-family: Consolas, Monaco, Courier, monospace;
  font-size: 1rem;
  line-height: 1.25rem;
  min-width: 2rem;
  padding: 0.5rem 0.2rem 0.5rem 0;
  text-align: right;
}

.line_numbers div {
  margin-bottom: 0rem;
}
