.grid {
  display: grid;
  grid-column-gap: 1rem;
}

@media (max-width: 1000px) {
  .grid {
    display: block;
  }
}
