.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0;
}

.checkbox {
  background-color: var(--color-gray-light-p87);
  border: 1px solid var(--color-gray-light-n25);
  border-radius: 4px;
  color: var(--color-gray-dark-n75);
  cursor: pointer;
  font-size: 1rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 0;
  padding: 0.15rem 0.25rem;
  transition: border-color 0.25s;
  width: 1.5rem;
}

.checkbox:hover {
  border-color: var(--color-gray-light-n37);
}

.checkmark {
  transition: opacity 0.25s;
  opacity: 0;
}

.checkmark.checked {
  opacity: 1;
}
