.container {
  align-items: center;
  display: flex;
  margin-top: 1rem;
}

.user_details_container {
  flex: 1;
}

.user_details_container button {
  margin-bottom: 0.5rem;
}
