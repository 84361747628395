.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0.75rem 0;
}

.container .container {
  margin: 0;
}

.label_container {
  margin-bottom: 0.25rem;
  padding: 0 0.25rem;
}

.label {
  align-self: center;
  flex: 2;
  font-size: 0.85rem;
  font-weight: 600;
  margin-right: 1rem;
}

.help_text {
  color: var(--color-gray-light-n62);
  font-weight: 400;
  opacity: 0.75;
}

.content {
  align-items: center;
  display: flex;
  flex: 2;
}

@media only screen and (max-device-width: 768px) {
  .container {
    flex-direction: column;
  }

  .label {
    align-self: flex-start;
  }
}
