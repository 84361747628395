.photo {
  align-items: center;
  background-color: var(--color-primary-accent);
  background-size: cover;
  border-radius: 50%;
  color: var(--color-primary-accent-p75);
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  margin-right: 1rem;
}

.small {
  height: 2rem;
  width: 2rem;
}

.medium {
  height: 3rem;
  width: 3rem;
}

.large {
  height: 6rem;
  width: 6rem;
}
